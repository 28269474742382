import React from 'react';
import {useSelector} from "react-redux";
import {get} from 'lodash';

import Graph from './Graph';

export default function ({printable}) {

    // Only show data graph in Mindsets University Dashboard for now
    // const dashboard = useSelector(state => state.insights_dashboard);
    // const school_name = get(dashboard, 'project.school.name', '');
    // const district_name = get(dashboard, 'project.district.name', '');
    // if (school_name.toUpperCase() != "MINDSETS UNIVERSITY" && district_name.toUpperCase() != "MINDSETS UNIVERSITY") {
    //     return null;
    // }

    const dashboard_data = useSelector(state => state.dashboard_data);
    const domain_data = dashboard_data.domain_data;
    if (!domain_data || !domain_data.categories.length) {
        return null;
    }
    const skill_data = dashboard_data.skill_data;
    if (!skill_data || !skill_data.categories.length) {
        return null;
    }

    return (
        <div className="flex-3">
            <Graph
                printable={printable}
            />
        </div>
    );
}
