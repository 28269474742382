import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from "react-redux";
import {find} from "lodash";
import {LoadingAction, CustomDropdown} from "mindsets-js-sdk";

import {userCan} from 'utils/membership';
import ExportToGoogleDrive from './ExportToGoogleDrive';
import FeatureLock from 'common/membership/FeatureLock';


const exportToGoogleDrive = new ExportToGoogleDrive();

function ExportAction({dropdownClass = ''}) {
    const dispatch = useDispatch();
    const me = useSelector(state => state.me);
    const selected_practice_id = useSelector(state => state.pam_selected_practice_id);
    const practices = useSelector(state => state.pam_practices);
    const skill_structure_data = useSelector(state => state.pam_challenge_skill_structure);
    const student_skill_data = useSelector(state => state.pam_student_skill_data);

    const selected_practice = find(practices, {id: selected_practice_id});

    function onExportToSheetsGoogleClick(e) {
        e.preventDefault();
        e.stopPropagation();

        if (!canCreate()) {
            if (confirm("Upgrade to unlock this feature.\n Would you like to be redirected to a pricing page?")) {
                window.open(process.env.PUBLIC_PRICING_URL, '_blank');
            }
            return false;
        }

        dispatch(LoadingAction(true, 'Exporting...'));
        exportToGoogleDrive.execute(
            {
                skill_structure_data,
                student_skill_data,
                practice_code: selected_practice.code
            },
            dispatch
        );
    }

    function canCreate() {
        return !!me && userCan(me.subscription_status, 'download_class_report')
    }

    function renderDropdownTrigger() {
        return (
            <FeatureLock
                enable={canCreate()}
            >
                <button className="btn btn-flat">
                    <i className="fas fa-download"></i>
                    <span>{`Export Report`}</span>
                </button>
            </FeatureLock>
        );
    }

    return (
        <CustomDropdown
            trigger={renderDropdownTrigger()}
            no_wrap={true}
            items={{
                google: <a href={"#!"} onClick={onExportToSheetsGoogleClick}>Export to Google Sheets</a>
            }}
            content_class={dropdownClass}
            use_arrow={false}
        />
    );
}

ExportAction.propTypes = {
    dropdownClass: PropTypes.string
};

export default ExportAction
