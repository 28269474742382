import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";

import FeatureLock from 'common/membership/FeatureLock';
import {userCan} from 'utils/membership';
import analytic from 'utils/Analytic';

function ProjectExtensionLayer({challenge_code, projectExtension}) {
    const navigate = useNavigate();
    let me = useSelector(state => state.me);

    const onClick = (language) => (e) => {
        e.stopPropagation();

        analytic.log('projectView', {
            challenge_code,
            project_name: projectExtension.name,
            language
        });
    }

    function previewChallenge(e) {
        e.stopPropagation();
        e.preventDefault();

        analytic.log('projectViewChallenge', {
            challenge_code,
            project_name: projectExtension.name
        });

        navigate(`/explore/${challenge_code}`);
    }

    const renderViewButton = () => {
        return (
            <FeatureLock
                enable={userCan(me.subscription_status, 'download_project_extension')}
                title={`Mindsets! Project Library`}
                feature_type={`project_extension`}
            >
                <a
                    className='btn btn-large btn-on-dark'
                    href={projectExtension.url}
                    target='_blank'
                    onClick={onClick('default')}
                >
                    {`View`}
                </a>
            </FeatureLock>
        )
    }

    const renderLinks = () => {
        if (!projectExtension.links) {
            return null;
        }

        return projectExtension.links.map((oneLink, key) => {
            return (
                <FeatureLock
                    key={key}
                    enable={userCan(me.subscription_status, 'download_project_extension')}
                    title={`Mindsets! Project Library`}
                    feature_type={`project_extension`}
                >
                    <a
                        key={key}
                        className='p-e-l-link btn btn-on-dark'
                        href={oneLink.url}
                        target='_blank'
                        onClick={onClick(oneLink.title)}
                    >
                        <i className='fas fa-globe'/>
                        <span>&nbsp;</span>
                        {oneLink.title}
                    </a>
                </FeatureLock>
            );
        });
    }

    return (
        <div className="project-extension-layer flex-column">
            <div className="p-e-l-top flex-row flex-h-center flex-v-end">
                {renderViewButton()}
            </div>

            <div className="flex-auto flex-column flex-vh-center">
                {renderLinks()}
            </div>

            <div className="p-e-l-bottom flex-row flex-h-center">
                <button
                    className='btn btn-on-dark'
                    onClick={previewChallenge}
                >
                    {`Paired Challenge`}
                </button>
            </div>
        </div>
    );
}

ProjectExtensionLayer.propTypes = {
    projectExtension: PropTypes.object.isRequired
};

export default ProjectExtensionLayer;