import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment'
import MindsetsJsSDK, {actions} from "mindsets-js-sdk";

import {DATE_FORMAT} from 'constants/index'
import analytic from 'utils/Analytic'
import {userCan} from 'utils/membership'
import FeatureLock from 'common/membership/FeatureLock'


class CreateClassCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lesson_created: undefined
        };
    }

    canCreate() {
        const {challenge_version} = this.props
        return !!this.props.me && userCan(this.props.me.subscription_status, 'create_mindsets_code', {challenge_version}) && !challenge_version.is_locked
    }

    createClassCode() {
        return (e) => {
            if (e) {
                e.preventDefault();
            }

            if (!this.canCreate()) {
                window.open(process.env.PUBLIC_PRICING_URL, '_blank');
                return
            }

            this.props.beforeCreate();
            this.props.dispatch(actions.LoadingAction(true, 'Creating Mindsets Code'));
            MindsetsJsSDK().Api.lesson.create({
                challenge_version_id: this.props.challenge_version.id,
                name: moment().format(DATE_FORMAT) + ' Class'
            }).then((response) => {
                this.setState({lesson_created: response.lesson});
                this.props.afterCreate();
                this.props.dispatch(actions.LoadingAction(false));
                analytic.log('createClassCode', {
                    name: this.props.challenge_version.name,
                    code: this.props.challenge_version.challenge_code.code
                }, response.lesson);
                this.props.navigate(`/teacher/class/${response.lesson.code}/setup`);
            }).catch((error) => {
                this.props.afterCreate();
                this.props.dispatch(actions.LoadingAction(false));
                console.error(error);
            })
        }
    }

    render() {
        return (
            <FeatureLock
                enable={this.canCreate()}
                message={this.props.challenge_version.is_locked ? 'Please use the Challenges selected by your district administrator. This Challenge is not selected at this time' : undefined}
                show_price_link={!this.props.challenge_version.is_locked}
                title={this.props.challenge_version.is_locked ? 'This Mindsets Challenge is locked' : undefined}
                feature_type={`create_class_code`}
                onClickCallback={() => {
                    if (this.props.challenge_version.is_locked) {
                        analytic.log('openChallengeLock', {
                            challenge_code: this.props.challenge_version.challenge_code.code,
                            challenge_name: this.props.challenge_version.name,
                            challenge_version: this.props.challenge_version.version,
                            element: "Create Class Code button"
                        });
                    }
                }}
            >
                <button className={this.props.button_class} onClick={this.createClassCode()}>
                    <i className="fas fa-plus"/>
                    <span>
                        {`Create`}
                    </span>
                </button>
            </FeatureLock>
        )
    }
}

CreateClassCode.defaultProps = {
    challenge_version: {},
    beforeCreate: () => {
    },
    afterCreate: () => {
    },
    button_class: 'btn btn-on-dark'
};

CreateClassCode.propTypes = {
    challenge_version: PropTypes.shape({
        challenge_code: PropTypes.shape({
            id: PropTypes.number.isRequired,
            code: PropTypes.string.isRequired
        }).isRequired,
        is_locked: PropTypes.bool.isRequired
    }).isRequired,
    beforeCreate: PropTypes.func,
    afterCreate: PropTypes.func,
    button_class: PropTypes.string,
    navigate: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    me: PropTypes.shape({
        subscription_status: PropTypes.object.isRequired,
        school: PropTypes.shape({
            district_id: PropTypes.number
        })
    })
};

export default props => (
    <CreateClassCode
        {...props}
        navigate={useNavigate()}
        dispatch={useDispatch()}
        me={useSelector(state => state.me)}
    />
);
