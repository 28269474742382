import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {intersection} from "lodash";
import {MultipleSelector} from "mindsets-js-sdk";

import {DASHBOARD_TYPE_FILTERS, FILTERS_MAP} from "./index";
import {
    LoadInsightsDashboardAchievementLevelFilterAction,
    LoadInsightsDashboardChallengeFilterAction,
    LoadInsightsDashboardGradeFilterAction,
    LoadInsightsDashboardImpactStudyFilterAction,
    LoadInsightsDashboardPracticeFilterAction,
    LoadInsightsDashboardPracticeSkillFilterAction,
    LoadInsightsDashboardSchoolFilterAction,
    LoadInsightsDashboardStudentFilterAction, LoadInsightsDashboardSubjectFilterAction,
    LoadInsightsDashboardTeacherFilterAction,
    SetDashboardFiltersAction
} from "../../../actions/performance_actions";

export default function ({filter_key, id}) {
    const dispatch = useDispatch();
    const params = useParams();
    const dashboards = useSelector(state => state.dashboards);
    const dashboard_filters = useSelector(state => state.dashboard_filters);
    const dashboard_impact_study_filter = useSelector(state => state.dashboard_impact_study_filter);
    const dashboard_practice_filter = useSelector(state => state.dashboard_practice_filter);
    const dashboard_subject_filter = useSelector(state => state.dashboard_subject_filter);
    const dashboard_grade_filter = useSelector(state => state.dashboard_grade_filter);
    const dashboard_school_filter = useSelector(state => state.dashboard_school_filter);
    const dashboard_challenge_filter = useSelector(state => state.dashboard_challenge_filter);
    const dashboard_teacher_filter = useSelector(state => state.dashboard_teacher_filter);
    const dashboard_practice_skill_filter = useSelector(state => state.dashboard_practice_skill_filter);
    const dashboard_student_filter = useSelector(state => state.dashboard_student_filter);
    const dashboard_achievement_level_filter = useSelector(state => state.dashboard_achievement_level_filter);
    const dashboard_filter_dependencies = useSelector(state => state.dashboard_filter_dependencies);
    let dashboard = dashboards.find(dashboard => dashboard.id === parseInt(params.dashboard_id)) || {};
    if (!Object.keys(dashboard).length) {
        dashboard = useSelector(state => state.insights_dashboard);
    }

    useEffect(() => {
        let action;
        switch (filter_key) {
            case 'impact_study':
                action = LoadInsightsDashboardImpactStudyFilterAction;
                break;
            case 'practice_code':
                action = LoadInsightsDashboardPracticeFilterAction;
                break;
            case 'subject':
                action = LoadInsightsDashboardSubjectFilterAction;
                break;
            case 'class_grade':
                action = LoadInsightsDashboardGradeFilterAction;
                break;
            case 'school_id':
                action = LoadInsightsDashboardSchoolFilterAction;
                break;
            case 'challenge_code':
                action = LoadInsightsDashboardChallengeFilterAction;
                break;
            case 'teacher_id':
                action = LoadInsightsDashboardTeacherFilterAction;
                break;
            case 'practice_skill_id':
                action = LoadInsightsDashboardPracticeSkillFilterAction;
                break;
            case 'student_id':
                action = LoadInsightsDashboardStudentFilterAction;
                break;
            case 'achievement_level':
                action = LoadInsightsDashboardAchievementLevelFilterAction;
                break;
            default:
                throw 'Unknown filter: ' + filter_key
        }
        dispatch(action(params.dashboard_id));
    }, []);

    function getFilterData() {
        switch (filter_key) {
            case 'impact_study':
                return dashboard_impact_study_filter.map(item => {
                    return {
                        key: item.impact_study,
                        label: item.impact_study
                    }
                });
            case 'practice_code':
                return dashboard_practice_filter.map(item => {
                    return {
                        key: item.practice_code,
                        label: item.practice_name
                    }
                });
            case 'subject':
                return dashboard_subject_filter.map(item => {
                    return {
                        key: item.subject_id,
                        label: item.subject_name
                    }
                });
            case 'class_grade':
                return dashboard_grade_filter.map(item => {
                    return {
                        key: item,
                        label: item
                    }
                });
            case 'school_id':
                return dashboard_school_filter.map(item => {
                    return {
                        key: item.school_id,
                        label: item.school_name
                    }
                });
            case 'challenge_code':
                return dashboard_challenge_filter.map(item => {
                    return {
                        key: item.challenge_code,
                        label: item.challenge_name
                    }
                });
            case 'teacher_id':
                return dashboard_teacher_filter.map(item => {
                    return {
                        key: item.teacher_id,
                        label: item.teacher_name
                    }
                });
            case 'practice_skill_id':
                return dashboard_practice_skill_filter.map(item => {
                    return {
                        key: item.practice_skill_id,
                        label: item.practice_skill_code + ' - ' + item.practice_skill_name
                    }
                });
            case 'student_id':
                return dashboard_student_filter.map(item => {
                    return {
                        key: item.student_id,
                        label: item.student_name
                    }
                });
            case 'achievement_level':
                return dashboard_achievement_level_filter.map(item => {
                    return {
                        key: item.achievement_level,
                        label: item.achievement_level_name
                    }
                });
            default:
                throw 'Unknown filter ' + filter_key
        }
    }

    if (!dashboard_filters[id]) {
        return null;
    }

    if (Object.keys(DASHBOARD_TYPE_FILTERS).includes(dashboard.type) && DASHBOARD_TYPE_FILTERS[dashboard.type].includes(filter_key)) {
        const dependencies = dashboard_filter_dependencies.filter(row => {
            let is_true = true;
            DASHBOARD_TYPE_FILTERS[dashboard.type].map(dashboard_filter_key => {
                if (filter_key !== dashboard_filter_key && dashboard_filters[id][dashboard_filter_key]) {
                    if (Array.isArray(dashboard_filters[id][dashboard_filter_key])) {
                        if (dashboard_filters[id][dashboard_filter_key].length) {
                            if (row[dashboard_filter_key]) {
                                if (Array.isArray(row[dashboard_filter_key])) {
                                    if (intersection(row[dashboard_filter_key], dashboard_filters[id][dashboard_filter_key]).length <= 0) {
                                        is_true = false;
                                    }
                                } else {
                                    if (!dashboard_filters[id][dashboard_filter_key].includes(row[dashboard_filter_key])) {
                                        is_true = false;
                                    }
                                }
                            }
                        }
                    } else {
                        if (row[dashboard_filter_key]) {
                            if (Array.isArray(row[dashboard_filter_key])) {
                                if (row[dashboard_filter_key].includes(dashboard_filters[id][dashboard_filter_key])) {
                                    is_true = false;
                                }
                            } else {
                                if (dashboard_filters[id][dashboard_filter_key].toString() !== row[dashboard_filter_key].toString()) {
                                    is_true = false;
                                }
                            }
                        }
                    }
                }
            });

            return is_true;
        });
        const ids = [...new Set(dependencies.map(dep => dep[filter_key]).flat())];
        const options = getFilterData().filter(option => ids.includes(option.key));

        let display = FILTERS_MAP[filter_key].default_label;
        const items = dashboard_filters[id][filter_key];
        if (items.length === 1) {
            const option = options.find(o => o.key === items[0]);
            if (option) {
                display = option.label;
            }
        } else if (items.length > 1) {
            display = items.length + ' selected';
        }

        return (
            <MultipleSelector
                disabled={options.length <= 0}
                trigger={(
                    <div className="flex-row flex-v-center like-selector">{display}</div>
                )}
                item_name={filter_key}
                items={options}
                selected={dashboard_filters[id][filter_key]}
                callback={selected => {
                    const dashboard_filters_copy = JSON.parse(JSON.stringify(dashboard_filters));
                    dashboard_filters_copy[id][filter_key] = selected;
                    dispatch(SetDashboardFiltersAction(dashboard_filters_copy));
                }}
                item_id_key='key'
                use_search={FILTERS_MAP[filter_key].useSearch}
            />
        );
    }

    return null;
}
