import React, {useState} from 'react';

import "./teacher_steps.scss";

export default function () {
    const [show, setShow] = useState(true);

    function close(e) {
        e.preventDefault();
        setShow(false);
    }

    function renderSteps() {
        return (
            <div className="flex-row flex-v-start">
                <div className="flex-1 center-align">
                    <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_teacher_step_1.svg`} alt='Step 1'/>
                    <h5 className="header-new">{'Step 1'}</h5>
                    <div>{'Preview a Mindsets! Challenge'}</div>
                </div>

                <i className="teacher-step-arrow fas fa-chevron-right fa-2x"></i>

                <div className="flex-1 center-align">
                    <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_teacher_step_2.svg`} alt='Step 2'/>
                    <h5 className="header-new">{'Step 2'}</h5>
                    <div>{'Create & share a Mindsets Code'}</div>
                </div>

                <i className="teacher-step-arrow fas fa-chevron-right fa-2x"/>

                <div className="flex-1 center-align">
                    <img src={`${process.env.PUBLIC_S3_ASSETS_URL}/application/icon_teacher_step_3.svg`} alt='Step 3'/>
                    <h5 className="header-new">{'Step 3'}</h5>
                    <div>{`View students' progress instantly`}</div>
                </div>
            </div>
        );
    }

    if (!show) {
        return null
    }

    return (
        <div className="teacher-steps-container hide-on-small-only">
            <div className="container">
                <h4 className="header-new center-align">
                    {`Hello, welcome to Mindsets!`}
                </h4>

                <h5 className="header-new center-align">
                    {`Get started in 3 easy steps.`}
                </h5>

                <div className="teacher-steps-detail-container">
                    {renderSteps()}
                </div>

                <h6 className="header-new">
                    <div className="flex-row flex-vh-center flex-box-1-2">
                        <div>
                            {`For more help, view the`}
                        </div>
                        <a className="link-new"
                           href={`${process.env.PUBLIC_S3_ASSETS_URL}/resources/class_setup.pdf`} target="_blank">
                            {`easy Setup Guide.`}
                        </a>
                    </div>
                </h6>
            </div>

            <div className="teacher-steps-close-container" onClick={close}>
                <i className="fas fa-times"/>
            </div>
        </div>
    );
}
