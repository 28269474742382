import React from 'react';
import {useSelector} from "react-redux";
import moment from 'moment';

import {DATE_FORMAT} from 'constants/index.js';

export default function () {

    return (
        <div className='profile-membership-card'>
            <div className="">
                <div className="flex-row">
                    <div className={'plan-logo teacher'}>
                        <i className="fas fa-address-card"></i>
                    </div>
                </div>
                <div className='mt-2'>Membership</div>
                <h4 className="header-new mt-0">Teacher Plan</h4>
            </div>

            <div className="card-rows">
                {`To cancel your subscription, please contact hello@mindsets.com, within 30 days of your billing cycle.`}
            </div>
        </div>
    );
}
