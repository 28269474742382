import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import classNames from 'classnames';

import analytic from 'utils/Analytic';
import FeatureLockContent from './FeatureLockContent';

import './feature_lock.scss';


class FeatureLock extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modal_open: false
        }
    }

    renderLayer() {
        if (this.props.enable) {
            return null
        }

        const onClick = (e) => {
            e.stopPropagation();

            if (typeof this.props.onClickCallback === 'function') {
                this.props.onClickCallback();
            }
            this.setState({modal_open: true})
        }

        return (
            <div className="feature-lock-layer flex-row flex-vh-center" onClick={onClick}>
                <i className="fas fa-lock"></i>
            </div>
        )
    }

    renderTitle() {
        return (
            <h4 className="header-new">
                {this.props.title}
            </h4>
        )
    }

    renderModalContent() {
        return (
            <div className="center-align">
                <div className="feature-lock-icon">
                    <i className="fas fa-lock"></i>
                </div>

                {this.renderTitle()}

                <FeatureLockContent
                    message={this.props.message}
                    feature_type={this.props.feature_type}
                    show_price_link={this.props.show_price_link}
                />
            </div>
        )
    }

    renderModal() {
        if (this.props.enable) {
            return null
        }

        return (
            <Modal
                open={this.state.modal_open}
                onClose={() => this.setState({modal_open: false})}
                showCloseIcon={true}
                classNames={{modal: 'feature-lock-modal'}}
            >
                {this.renderModalContent()}
            </Modal>
        )
    }

    render() {
        const class_name = classNames('feature-lock', {
            'feature-enable': this.props.enable,
            'feature-disable': !this.props.enable
        })

        return (
            <div className={class_name}>
                {this.props.children}

                {this.renderLayer()}

                {this.renderModal()}
            </div>
        )
    }
}

FeatureLock.defaultProps = {
    enable: false,
    title: 'This feature is locked',
    show_price_link: true
}

FeatureLock.propTypes = {
    enable: PropTypes.bool,
    title: PropTypes.string,
    message: PropTypes.string,
    feature_type: PropTypes.string,
    onClickCallback: PropTypes.func,
    show_price_link: PropTypes.bool
}

export default FeatureLock
