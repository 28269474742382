import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {get} from 'lodash';
import Highcharts from 'highcharts';

import {getStateBasedTitle} from '../utils';

export default function ({printable}) {

    const dashboard = useSelector(state => state.insights_dashboard);
    const dashboard_data = useSelector(state => state.dashboard_data);

    const graph_id = 'pam-skill-graph';
    const skill_data = dashboard_data.skill_data;

    useEffect(() => {
        if (skill_data) {
            draw();
        }
    }, [skill_data]);

    if (skill_data === undefined) {
        return null;
    }

    function getPointWidth() {
        const n = get(skill_data, 'categories', []).length;
        return Math.round(200 / n);
    }

    function draw() {
        const graph = {
            chart: {
                type: 'bar',
                marginLeft: 230,
                height: 500
            },
            title: {
                text: getStateBasedTitle(dashboard).skill,
                style: {
                    fontWeight: 'bolder',
                    fontSize: '18px'
                }
            },
            xAxis: {
                categories: skill_data.categories,
                labels: {
                    style: {}
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Student Achievement'
                },
                labels: {
                    format: '{text}%'
                }
            },
            tooltip: {
                pointFormat: '<span>{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                shared: true
            },
            plotOptions: {
                bar: {
                    stacking: 'percent',
                    maxPointWidth: 60,
                    pointWidth: getPointWidth()
                },
                series: {
                    stacking: 'normal'
                }
            },
            series: skill_data.series,
            legend: {
                enabled: true,
                reversed: true
            },
            credits: {
                enabled: false
            }
        }

        if (printable) {
            graph.tooltip.enabled = false;
        }

        Highcharts.chart(graph_id, graph)
    }

    return (
        <div>
            <div id={graph_id} className="pam-component"/>
        </div>
    );
}
