import React from 'react';

import Steps from "common/TeacherSteps";

export default function () {
    return (
        <div id="stripe-success">
            <div className="container">
                <div className="section">
                    <h2 className="header-new">{`Welcome to Mindsets!`}</h2>
                </div>

                <Steps />

                <div className="section">
                    <div className="flex-row flex-h-center">
                        <a className="btn btn-challenge" href={`/teacher/home`}>
                            {`Start your Mindsets! Journey`}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
