import React from 'react';
import {useSelector} from "react-redux";

import TemplateTypeA from './TemplateTypeA';
import TemplateTypeB from './TemplateTypeB';

export default function ({dashboard_id, printable}) {
    const dashboard = useSelector(state => state.insights_dashboard);

    if (dashboard.type === 'TYPE 1') {
        return (
            <TemplateTypeA
                dashboard_id={dashboard_id}
                printable={printable}
            />
        );
    } else if (dashboard.type === 'TYPE 2') {
        return (
            <TemplateTypeB
                printable={printable}
            />
        );
    }

    return null;
}