import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {DateRangeSelector} from "mindsets-js-sdk";

import {DASHBOARD_TYPE_FILTERS} from "./index";
import {SetDashboardFiltersAction} from "../../../actions/performance_actions";

import './date_filter.scss';

export default function ({filter_key, id}) {
    const dispatch = useDispatch();
    const params = useParams();
    const dashboards = useSelector(state => state.dashboards);
    const dashboard_filters = useSelector(state => state.dashboard_filters);
    let dashboard = dashboards.find(dashboard => dashboard.id === parseInt(params.dashboard_id)) || {};
    if (!Object.keys(dashboard).length) {
        dashboard = useSelector(state => state.insights_dashboard);
    }

    if (Object.keys(DASHBOARD_TYPE_FILTERS).includes(dashboard.type) && DASHBOARD_TYPE_FILTERS[dashboard.type].includes(filter_key)) {
        const filter = dashboard_filters[id][filter_key].split(',');
        let from = null;
        let till = null;
        if (filter.length > 0 && !isNaN(parseInt(filter[0]))) {
            from = parseInt(filter[0]);
        }
        if (filter.length > 1 && !isNaN(parseInt(filter[1]))) {
            till = parseInt(filter[1]);
        }

        return (
            <div className='date-filter'>
                <DateRangeSelector
                    from={from}
                    till={till}
                    onUpdate={data => {
                        const dashboard_filters_copy = JSON.parse(JSON.stringify(dashboard_filters));
                        dashboard_filters_copy[id][filter_key] = data.from + ',' + data.till;
                        dispatch(SetDashboardFiltersAction(dashboard_filters_copy));
                    }}
                    as={'selector'}
                    trigger_class='like-selector'
                />
            </div>
        );
    }

    return null;
}
