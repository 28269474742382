import React, {useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from "react-redux";
import MindsetsJsSDK from "mindsets-js-sdk";
import {debounce} from 'lodash';
import classNames from 'classnames';

import analytic from 'utils/Analytic';
import {SetDashboardDataAction, SetDashboardFiltersAction} from '../../../../actions/performance_actions';
import {default_filters} from "../../../../reducers/performance_reducers";
import PrintableHeader from './PrintableHeader';
import SkillGraph from './SkillGraph';
import CategoryGraph from './CategoryGraph';
import DomainGraph from './DomainGraph';
import SkillTable from './SkillTable';
import DomainTable from './DomainTable';
import Placeholder from './Placeholder';
import Filters from "../../Filters";

import "./pam_template.scss";
import "./pam_template_print.scss";

export default function ({dashboard_id, printable}) {
    const FILTER_1 = 'filter-1';

    const dispatch = useDispatch();

    const dashboard = useSelector(state => state.insights_dashboard);
    const dashboard_data = useSelector(state => state.dashboard_data);
    const dashboard_filters = useSelector(state => state.dashboard_filters);

    const debounceLoadAllData = useCallback(
        debounce((dashboard_id, dashboard_filters) => {
            MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/skills`, {...dashboard_filters}).then((response) => {
                dispatch(SetDashboardDataAction({skill_data: response}));
            });
            MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/categories`, {...dashboard_filters}).then((response) => {
                dispatch(SetDashboardDataAction({category_data: response}));
            });
            MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/domains`, {...dashboard_filters}).then((response) => {
                dispatch(SetDashboardDataAction({domain_data: response}));
            });

            MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/data`, {current: 1, ...dashboard_filters}).then((response) => {
                dispatch(SetDashboardDataAction({data_skill_table: response}));
            });

            MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/data-domain`, {current: 1, ...dashboard_filters}).then((response) => {
                dispatch(SetDashboardDataAction({data_domain_table: response}));
            });

            analytic.log('insightsLoadData', {
                dashboard_id,
                dashboard_title: dashboard.title,
                project_id: dashboard.project.id,
                project_name: dashboard.project.name,
                dashboard_filters: dashboard_filters
            });
        }, 1500),
        []
    );

    useEffect(() => {
        if (!printable) {
            Promise.resolve().then(() => {
                dispatch(SetDashboardFiltersAction({
                    [FILTER_1]: JSON.parse(JSON.stringify(default_filters))
                }));
            }).then(() => {
                debounceLoadAllData(dashboard_id, dashboard_filters[FILTER_1]);
            });
        }
    }, []);

    useEffect(() => {
        if (!printable) {
            debounceLoadAllData(dashboard_id, dashboard_filters[FILTER_1]);
        }
    }, [
        dashboard_id,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].impact_study : '',
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].subject : '',
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].class_grade.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].school_id.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].challenge_code.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].teacher_id.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].achievement_level.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].practice_skill_id.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].student_id.length : 0,
        dashboard_filters[FILTER_1] ? dashboard_filters[FILTER_1].class_date : ''
    ]);

    //-----------------------------------------------------
    // Skill table
    const debounceLoadSkillTableData = useCallback(
        debounce((dashboard_id, dashboard_filters, page) => {
            const current = page ? page : 1;
            MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/data`, {current, ...dashboard_filters}).then((response) => {
                dispatch(SetDashboardDataAction({data_skill_table: response}));
            });
        }, 1000),
        []
    );

    const selectSkillTablePage = (page) => {
        if (dashboard_data.data_skill_table.current == page) {
            return false;
        }
        debounceLoadSkillTableData(dashboard_id, dashboard_filters, page);
    }
    //-----------------------------------------------------

    //-----------------------------------------------------
    // Domain table
    const debounceLoadDomainTableData = useCallback(
        debounce((dashboard_id, dashboard_filters, page) => {
            const current = page ? page : 1;
            MindsetsJsSDK().API.get(`dashboards/${dashboard_id}/data-domain`, {current, ...dashboard_filters}).then((response) => {
                dispatch(SetDashboardDataAction({data_domain_table: response}));
            });
        }, 1000),
        []
    );

    const selectDomainTablePage = (page) => {
        if (dashboard_data.data_domain_table.current == page) {
            return false;
        }
        debounceLoadDomainTableData(dashboard_id, dashboard_filters, page);
    }
    //-----------------------------------------------------

    if (printable && !dashboard_data.skill_data) {
        return (
            <div>
                {`Missing data. Go back to the Insights Dashboard and start again.`}
            </div>
        );
    }

    const class_name = classNames('pam-template', {
        'pam-template-printable': printable
    });

    const renderFilter = () => {
        if (printable) {
            return null;
        }

        return (
            <Filters id={FILTER_1}/>
        );
    }

    return (
        <div>
            {renderFilter()}
            <div id="pam-template-type-a" className={class_name}>
                <PrintableHeader
                    printable={printable}
                />

                <div className="flex-row flex-gap-10 flex-wrap">
                    <SkillGraph
                        printable={printable}
                    />

                    <CategoryGraph
                        printable={printable}
                    />
                </div>

                <div className="flex-row flex-gap-10 flex-wrap">
                    <DomainGraph
                        printable={printable}
                    />

                    <Placeholder
                    />
                </div>

                <div>
                    <SkillTable
                        selectPage={selectSkillTablePage}
                        printable={printable}
                    />
                </div>

                <div>
                    <DomainTable
                        selectPage={selectDomainTablePage}
                        printable={printable}
                    />
                </div>
            </div>
        </div>
    );
}
