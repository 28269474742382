import React from 'react';
import {useSelector} from "react-redux";
import {get} from 'lodash';

export default function ({}) {

    const dashboard = useSelector(state => state.insights_dashboard);

    const district_state = get(dashboard, 'project.district.state', '');
    const state = get(dashboard, 'project.school.state', district_state);
    if (state.toLowerCase() != 'ca') {
        return null;
    }

    return (
        <div className="flex-2">
        </div>
    );
}
