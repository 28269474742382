export default function (Analytic) {
    Analytic.prototype.insightsSelectDashboard = function ({
        dashboard_id, dashboard_title, project_id, project_name
    }) {
        this.track('Select Insights Dashboard', {
            dashboard_id,
            dashboard_title,
            project_id,
            project_name
        });
    }

    Analytic.prototype.insightsLoadData = function ({
        dashboard_id, dashboard_title, project_id, project_name, dashboard_filters
    }) {
        this.track('Insights Dashboard Load Data', {
            dashboard_id,
            dashboard_title,
            project_id,
            project_name,
            dashboard_filters
        });
    }

    Analytic.prototype.insightsClickPrint = function ({
        dashboard_id, dashboard_title, project_id, project_name, dashboard_filters
    }) {
        this.track('Insights Dashboard Click Print', {
            dashboard_id,
            dashboard_title,
            project_id,
            project_name,
            dashboard_filters
        });
    }
}
