import React from 'react';
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";

import analytic from 'utils/Analytic';
import {userCan} from 'utils/membership';
import FeatureLock from 'common/membership/FeatureLock';

export default function () {
    const me = useSelector(state => state.me);
    const challenge_version = useSelector(state => state.challenge_version);
    const stream_entities = useSelector(state => state.challenge_version_stream_entities);
    const current_index = useSelector(state => state.challenge_preview_current_index);

    function renderResourcesButton() {
        return (
            <div className="social-sharing">
                <Link
                    to={`/explore/${challenge_version.challenge_code.code}/resources`}
                    className='btn btn-flat'
                ><i className="fas fa-list-alt"/>&nbsp;Resources</Link>
            </div>
        );
    }

    return (
        <div className="challenge-preview-footer z-depth-1">
            <div className="flex-row-m flex-between">
                <div className="flex-row flex-h-center">
                    {renderResourcesButton()}
                </div>

                <div className="flex-row flex-vh-center">
                    {current_index + 1}
                    {`/`}
                    {stream_entities.length}
                    {` pages`}
                </div>

                <div className="flex-row flex-h-center">
                    <FeatureLock
                        enable={!!me && userCan(me.subscription_status, 'download_challenge_guide', {challenge_version}) && !challenge_version.is_locked}
                        message={challenge_version.is_locked ? 'Please use the Challenges selected by your district administrator. This Challenge is not selected at this time' : undefined}
                        show_price_link={!challenge_version.is_locked}
                        title={challenge_version.is_locked ? 'This Mindsets Challenge is locked' : undefined}
                        feature_type={`teacher_guide`}
                        onClickCallback={() => {
                            if (challenge_version.is_locked) {
                                analytic.log('openChallengeLock', {
                                    challenge_code: challenge_version.challenge_code.code,
                                    challenge_name: challenge_version.name,
                                    challenge_version: challenge_version.version,
                                    element: "Teacher Guide button"
                                });
                            }
                        }}
                    >
                        <a
                            href={`/challenge/${challenge_version.challenge_code.code}/guide.pdf`}
                            target='_blank'
                            className="btn btn-flat"
                            onClick={() => {
                                analytic.log('challengeGuideDownloaded', {
                                    name: challenge_version.name,
                                    code: challenge_version.challenge_code.code
                                });
                            }}>
                            <i className="fas fa-file-download"/>
                            <span>{`Teacher Guide`}</span>
                        </a>
                    </FeatureLock>
                </div>
            </div>
        </div>
    );
}
