import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {get, findIndex, cloneDeep, remove, forEach} from 'lodash';
import Highcharts from 'highcharts';

import {getStateBasedTitle} from '../utils';

export default function ({printable}) {

    const dashboard = useSelector(state => state.insights_dashboard);
    const dashboard_data = useSelector(state => state.dashboard_data);

    const graph_id = 'pam-category-graph';
    let category_data = dashboard_data.category_data;

    // Only show data graph in Mindsets University Dashboard for now
    // This is tmp for testing
    // const school_name = get(dashboard, 'project.school.name', '');
    // const district_name = get(dashboard, 'project.district.name', '');
    // if (school_name.toUpperCase() != "MINDSETS UNIVERSITY" && district_name.toUpperCase() != "MINDSETS UNIVERSITY") {
    //     if (category_data) {
    //         const i = findIndex(category_data.categories, (n) => n == 'Math - Concepts & Procedures');
    //         if (i >= 0) {
    //             category_data = cloneDeep(category_data);
    //             remove(category_data.categories, (n, k) => k === i);
    //             forEach(category_data.series, (s) => {
    //                 remove(s.data, (n, k) => k === i);
    //             });
    //         }
    //     }
    // }

    useEffect(() => {
        if (category_data) {
            draw();
        }
    }, [category_data]);

    if (category_data === undefined) {
        return null;
    }

    function getPointWidth() {
        const n = get(category_data, 'categories', []).length;
        return Math.round(250 / n);
    }

    function draw() {
        const graph = {
            chart: {
                type: 'column',
                marginBottom: 150,
                height: 500
            },
            title: {
                text: getStateBasedTitle(dashboard).category,
                style: {
                    fontWeight: 'bolder',
                    fontSize: '18px'
                }
            },
            xAxis: {
                categories: category_data.categories
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'Student Achievement'
                },
                labels: {
                    format: '{text}%'
                }
            },
            tooltip: {
                pointFormat: '<span>{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
                shared: true
            },
            plotOptions: {
                column: {
                    stacking: 'percent',
                    maxPointWidth: 70,
                    pointWidth: getPointWidth()
                }
            },
            series: category_data.series,
            legend: {
                enabled: true,
                reversed: true
            },
            credits: {
                enabled: false
            }
        }

        if (printable) {
            graph.tooltip.enabled = false;
        }

        Highcharts.chart(graph_id, graph)
    }

    return (
        <div>
            <div id={graph_id} className="pam-component"/>
        </div>
    );
}
